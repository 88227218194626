import type { SVGProps } from 'react';

export function SvgCircleTelegram(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.275.934c-6.35 0-11.5 5.148-11.5 11.5 0 6.35 5.15 11.5 11.5 11.5 6.352 0 11.5-5.15 11.5-11.5 0-6.352-5.148-11.5-11.5-11.5M6.731 11.552l10.835-4.18c.495-.224.973.12.784.877l-1.845 8.695c-.129.618-.502.766-1.02.48l-2.81-2.076-.058.055-1.294 1.259-.01.01c-.152.148-.277.27-.552.27-.285 0-.31-.084-.367-.287a2 2 0 0 0-.061-.197l-1.034-3.17-2.702-.843c-.6-.184-.605-.597.134-.893m9.083-2.3-6.091 3.842 1.026 3.147.204-2.166 5.215-4.706c.229-.203-.05-.302-.354-.117"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
