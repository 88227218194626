import type { Dispatch, ReactElement, SetStateAction } from 'react';
import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useInViewport } from 'react-in-viewport';
import { useBreakpoints } from '../../../components/hooks/useBreakpoints';

type PpBlogPostTitle = {
  index: number;
  setParagraph: Dispatch<SetStateAction<number | undefined>>;
  title: string | null | undefined;
};

export function BlogPostTitle({ index, setParagraph, title }: PpBlogPostTitle): ReactElement {
  const { isMedium } = useBreakpoints();
  const ref = useRef(null);
  const { inViewport } = useInViewport(ref);
  const theme = useTheme();
  useEffect((): void => {
    if (!inViewport || isMedium) {
      return;
    }
    setParagraph(index);
  }, [inViewport, isMedium, setParagraph, index]);

  const styles = {
    title: css`
      max-width: ${`${theme.breakpoints.values.sm}px`};
      margin: ${theme.spacing(2)} auto;
    `,
  };
  return (
    <Typography
      ref={ref}
      color={theme.palette.websiteBrand.greyscale.black}
      component="h2"
      css={styles.title}
      variant="subHeadingM"
    >
      {title}
    </Typography>
  );
}
