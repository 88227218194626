import type { SVGProps } from 'react';

export function SvgCircleLinkedin(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.17.578C5.819.578.67 5.727.67 12.078s5.149 11.5 11.5 11.5 11.5-5.149 11.5-11.5-5.15-11.5-11.5-11.5M7.358 8.83a1.54 1.54 0 1 0-.01-3.08 1.54 1.54 0 0 0 .01 3.08m2.952 1.13 2.55-.006.003 1.17s.689-1.388 2.572-1.393c1.68-.004 3.073.821 3.08 3.347l.013 5.325-2.643.007-.012-4.68c-.003-1.49-.798-1.652-1.403-1.65-1.257.003-1.469 1.088-1.467 1.851l.011 4.486-2.682.007zm-1.607.005L6.02 9.97l.021 8.463 2.683-.006z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
