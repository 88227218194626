import { css } from '@emotion/react';
import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PlaceholderIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PlaceholderIcon';
import { difficultyColorPicker } from '../utils';

type PpDifficultyTag = {
  difficulty: string | null | undefined;
};

export function DifficultyTag({ difficulty }: PpDifficultyTag): React.ReactElement | null {
  const theme = useTheme();

  if (!difficulty) {
    return null;
  }
  const htmlColor = difficultyColorPicker(theme, difficulty);

  const icon = <PlaceholderIcon htmlColor={htmlColor} />;

  const styles = {
    chip: css`
      rect {
        fill: ${htmlColor};
      }
    `,
  };

  return <Chip color="secondary" css={styles.chip} icon={icon} label={difficulty} />;
}
