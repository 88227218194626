//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgCircleWhatsapp } from '../icons/CircleWhatsapp';

export function CircleWhatsappIcon(
  props: Omit<SvgIconProps, 'component' | 'children'>,
): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgCircleWhatsapp} data-qa="CircleWhatsappIcon">
      <svg />
    </SvgIcon>
  );
}
