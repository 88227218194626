import type { SVGProps } from 'react';

export function SvgCircleMail(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={23} rx={11.5} width={23} x={0.881} y={0.934} />
      <rect height={6.989} rx={1.5} stroke="#fff" width={10.894} x={6.932} y={8.939} />
      <path
        d="m9.202 11.205 2.607 1.993a1 1 0 0 0 1.215 0l2.607-1.993"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
