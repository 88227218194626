import type { SVGProps } from 'react';

export function SvgCircleFacebook(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M11.723.934c-6.352 0-11.5 5.148-11.5 11.5 0 6.35 5.148 11.5 11.5 11.5 6.35 0 11.5-5.15 11.5-11.5 0-6.352-5.15-11.5-11.5-11.5m3.36 9.62-.359 2.275h-1.882v5.5a8.3 8.3 0 0 1-2.523 0v-5.5h-2.05v-2.275h2.05V8.821c0-1.972 1.205-3.062 3.05-3.062.883 0 1.808.154 1.808.154v1.936h-1.02c-1.002 0-1.315.607-1.315 1.23v1.475z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
