import type { Theme } from '@mui/material';

export function difficultyColorPicker(theme: Theme, difficulty: string): string {
  switch (difficulty) {
    case 'Beginner':
      return theme.palette.system.green.dark;
    case 'Intermediate':
      return theme.palette.system.orange.main;
    default:
      return theme.palette.system.red.main;
  }
}
