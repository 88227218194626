import type { SVGProps } from 'react';

export function SvgCircleWhatsapp(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="currentColor" height={23} rx={11.5} width={23} x={0.328} y={0.934} />
      <path
        d="M12.356 6.328c-3.745 0-6.794 2.936-6.795 6.544 0 1.154.313 2.28.907 3.272l-.964 3.391 3.602-.91a7 7 0 0 0 3.248.797h.002c3.746 0 6.794-2.936 6.795-6.545.001-1.747-.705-3.392-1.988-4.629a6.88 6.88 0 0 0-4.807-1.92m0 11.989h-.002a5.8 5.8 0 0 1-2.875-.759l-.207-.117-2.137.54.571-2.008-.134-.206a5.3 5.3 0 0 1-.864-2.895c.001-3 2.535-5.438 5.65-5.438 1.509 0 2.927.567 3.993 1.595a5.3 5.3 0 0 1 1.653 3.848c-.001 3-2.535 5.44-5.648 5.44m3.098-4.075c-.17-.081-1.005-.477-1.16-.532-.156-.054-.269-.082-.382.081a10 10 0 0 1-.537.641c-.1.11-.199.123-.369.041s-.717-.254-1.365-.812a5 5 0 0 1-.944-1.132c-.1-.163-.011-.252.074-.333.076-.073.17-.191.254-.287.085-.095.113-.163.17-.273.057-.108.028-.204-.014-.286s-.382-.887-.524-1.214c-.137-.319-.277-.276-.382-.281a8 8 0 0 0-.326-.006.64.64 0 0 0-.452.205c-.156.164-.594.559-.594 1.364S9.51 13 9.596 13.11c.085.108 1.197 1.76 2.9 2.47.406.167.722.268.969.344.406.124.777.106 1.069.064.326-.047 1.005-.395 1.146-.777s.141-.709.099-.777-.155-.11-.325-.192"
        fill="#fff"
      />
    </svg>
  );
}
