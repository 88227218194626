import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Chip, Link as MuiLink, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatter } from '../../../../generated/types';
import { useBreakpoints } from '../../../components/hooks/useBreakpoints';

type PpRelatedPosts = {
  posts: Array<{
    frontmatter?: MarkdownRemarkFrontmatter | null | undefined;
    id: string;
    numberOfCommonTags: number;
  }> | null;
};

export function RelatedPosts({ posts }: PpRelatedPosts): ReactElement | null {
  const theme = useTheme();
  const { isSmall } = useBreakpoints();
  const styles = {
    card: css`
      text-decoration: none;
      border-bottom: 1px solid ${theme.palette.websiteBrand.greyscale.grey};
      padding-bottom: ${theme.spacing(2)};
      :first-of-type {
        border-top: 1px solid ${theme.palette.websiteBrand.greyscale.grey};
        padding-top: ${theme.spacing(2)};
      }
    `,
    container: css`
      margin-top: ${theme.spacing(14)};
      width: 100%;
      ${theme.breakpoints.down('lg')} {
        margin-top: ${theme.spacing(10)};
      }
    `,
    text: css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
  };
  return (
    <Stack css={styles.container} direction="column" maxWidth="sm" spacing={2}>
      <Typography component="h4" variant="bodyHeadingS">
        Related Posts
      </Typography>
      {posts &&
        posts.map((post) => {
          if (!post.frontmatter) {
            return null;
          }
          return (
            <MuiLink key={post.id} css={styles.card} href={`/blog/${post.frontmatter.slug || ''}`}>
              <Stack direction="row" justifyContent="space-between" marginBottom={1}>
                <Typography
                  color="text.primary"
                  component="h5"
                  css={styles.text}
                  variant={isSmall ? 'paragraphBodyMBold' : 'paragraphBodyLBold'}
                >
                  {post.frontmatter.title}
                </Typography>
                <Chip label={post.frontmatter.category} />
              </Stack>
              <Typography color="text.dark" variant="paragraphBodyS">
                {post.frontmatter.intro}
              </Typography>
            </MuiLink>
          );
        })}
    </Stack>
  );
}
