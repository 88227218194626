import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CircleFacebookIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleFacebookIcon';
import { CircleLinkedinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleLinkedinIcon';
import { CircleMailIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleMailIcon';
import { CircleTelegramIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTelegramIcon';
import { CircleTwitterIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleTwitterIcon';
import { CircleWhatsappIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CircleWhatsappIcon';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import type { Maybe } from '../../../../generated/types';

type PpSharePost = {
  message: Maybe<string> | undefined;
  slug: Maybe<string> | undefined;
  url: string;
};

export function SharePost({ message, slug, url }: PpSharePost): React.ReactElement {
  const theme = useTheme();
  const defaultSharingMessage = 'Check out this blog post from NOAH!';
  return (
    <Stack alignItems="center" spacing={2} width="100%">
      <Typography variant="paragraphBodyS">Share</Typography>
      <Stack alignItems="center" direction="row" spacing={2}>
        <LinkedinShareButton
          id={`${slug as string}-linkedin-blog-share`}
          summary={message || defaultSharingMessage}
          url={url}
        >
          <CircleLinkedinIcon htmlColor={theme.palette.websiteBrand.greyscale.mediumGrey} />
        </LinkedinShareButton>
        <TwitterShareButton
          id={`${slug as string}-twitter-blog-share`}
          title={message || defaultSharingMessage}
          url={url}
        >
          <CircleTwitterIcon htmlColor={theme.palette.websiteBrand.greyscale.mediumGrey} />
        </TwitterShareButton>
        <FacebookShareButton
          id={`${slug as string}-facebook-blog-share`}
          quote={message || defaultSharingMessage}
          url={url}
        >
          <CircleFacebookIcon htmlColor={theme.palette.websiteBrand.greyscale.mediumGrey} />
        </FacebookShareButton>
        <TelegramShareButton
          id={`${slug as string}-telegram-blog-share`}
          title={message || defaultSharingMessage}
          url={url}
        >
          <CircleTelegramIcon htmlColor={theme.palette.websiteBrand.greyscale.mediumGrey} />
        </TelegramShareButton>
        <WhatsappShareButton
          id={`${slug as string}-whatsapp-blog-share`}
          title={message || defaultSharingMessage}
          url={url}
        >
          <CircleWhatsappIcon htmlColor={theme.palette.websiteBrand.greyscale.mediumGrey} />
        </WhatsappShareButton>
        <EmailShareButton
          body={message || defaultSharingMessage}
          id={`${slug as string}-email-blog-share`}
          url={url}
        >
          <CircleMailIcon htmlColor={theme.palette.websiteBrand.greyscale.mediumGrey} />
        </EmailShareButton>
      </Stack>
    </Stack>
  );
}
