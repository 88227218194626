import { graphql, useStaticQuery } from 'gatsby';
import type { DisclaimerHookQuery } from '../../../generated/types';

type PpUseDisclaimer = {
  disclaimer: string;
};
export function useDisclaimer(): PpUseDisclaimer {
  const data: DisclaimerHookQuery = useStaticQuery(graphql`
    query DisclaimerHook {
      markdownRemark(frontmatter: { layout: { eq: "info" } }) {
        frontmatter {
          disclaimer
        }
      }
    }
  `);

  const disclaimer = data?.markdownRemark?.frontmatter?.disclaimer || '';

  return {
    disclaimer,
  };
}
