import type { SVGProps } from 'react';

export function SvgCircleTwitter(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 23 23"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#circle-twitter_svg__a)">
        <path
          clipRule="evenodd"
          d="M11.5 0C17.851 0 23 5.149 23 11.5S17.851 23 11.5 23 0 17.851 0 11.5 5.149 0 11.5 0m5.86 5-4.372 5.081L17.742 17h-3.497l-3.2-4.66L7.034 17H6l4.584-5.328L6 5h3.496l3.032 4.412L16.323 5zm-6.255 6.066.464.664 3.164 4.526h1.59l-3.876-5.546-.464-.664L9 5.78H7.41z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="circle-twitter_svg__a">
          <path d="M0 0h23v23H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
